import React from 'react';
import BlogArticle from 'views/BlogArticle';

// eslint-disable-next-line react/prop-types
const BlogArticlePage = ({ pageContext }) => {
  // eslint-disable-next-line react/prop-types
  const { id, title, content, tags, date_created, editor } = pageContext;
  return <BlogArticle
    id={id}
    title={title}
    content={content}
    tags={tags}
    date_created={date_created}
    editor={editor}
  />;
};

export default BlogArticlePage;
