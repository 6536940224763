import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Content, FooterNewsletter, Hero } from './components';
import { SidebarArticles, SimilarStories } from '../BlogArticle2/components';
import useMediaQuery from '@mui/material/useMediaQuery';

// eslint-disable-next-line react/prop-types
const BlogArticle = ({ id }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      const response = await fetch(`https://cms.cognijiwa.ai/items/blogs/${id}?fields=*.*&filter={"status":{"_eq":"published"}}`);
      if (!response.ok) {
        console.error('Failed to fetch article');
        return;
      }
      const data = await response.json();
      setArticle(data.data);
    };

    fetchArticle();
  }, [id]); // Depend on id to refetch if it changes

  if (!article) {
    return <div>Loading...</div>; // Placeholder for loading state
  }

  // Destructure the article data for easier access
  const { title, content, tags, date_created, editor } = article;

  return (
    <Main colorInvert={true}>
      <Box>
        <Hero
          title={title}
          date_created={date_created}
          editor={editor}
        />
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Content
                id={id}
                title={title}
                content={content}
                tags={tags}
                date_created={date_created}
                editor={editor}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {isMd ? (
                <Box marginBottom={4}>
                  <SidebarArticles />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={'alternate.main'}>
        {/* <Container>
          <SimilarStories />
        </Container> */}
        <Container>
          <FooterNewsletter />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default BlogArticle;
